
.ProseMirror {
  @apply bg-white text-neutral-900 dark:bg-white dark:text-neutral-900;
    @apply caret-black dark:caret-black outline-0 pr-8 pl-20 py-16 z-0 lg:pl-8 lg:pr-8;
  
    & > * {
      @apply mx-auto max-w-2xl;
    }
  
    .selection {
      @apply inline;
    }
  
    .selection,
    *::selection {
      @apply bg-black/10 dark:bg-black/10;
    }
  
    & > .react-renderer {
      @apply my-12 first:mt-0 last:mb-0;
    }
  
    &.resize-cursor {
      @apply cursor-col-resize;
    }
  
    .ProseMirror-gapcursor {
      @apply relative w-full max-w-2xl mx-auto;
  
      &:after {
        @apply border-t-black/40 dark:border-t-black/40 w-full -top-[1.5em] max-w-2xl mx-auto left-0 right-0;
      }
    }
  }
  
  [data-theme='slash-command'] {
    width: 1000vw;
  }


