.circles {
  height: 100vmin;
  /* position: relative; */
  width: 100vmin;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-top: -40px;

  > div {
    animation: growAndFade 5s infinite ease-out;
    background-color: #d3d3d3;
    border-radius: 50%;
    height: 60%;
    opacity: 0;
    position: absolute;
    width: 60%;
  }

  .circle1 {
    animation-delay: 1s;
  }
  .circle2 {
    animation-delay: 2s;
  }
  .circle3 {
    animation-delay: 3s;
  }
  .circle4 {
    animation-delay: 4s;
  }
}

@keyframes growAndFade {
  0% {
    opacity: 0.25;
    transform: scale(0);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
