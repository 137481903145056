.slider {
    overflow: hidden;
    width: 100%;
  }
  
  .slide-track {
    display: flex;
    animation: slide-animation 40s linear infinite;
  }
  
  .slide {
    flex: 0 0 auto;
  }
  
  .slide img {
    height:55px;
    width: auto; /* Adjust width as needed */
  }
  
  @keyframes slide-animation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  