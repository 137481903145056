@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css?family=Righteous");
@import url("https://fonts.googleapis.com/css?family=Raleway");
@import "./App.css";

body {
  height: 100%;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3535351a;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.back-blur {
  background: rgba(255, 255, 255, 0.1);
}

.white-back {
  background: linear-gradient(180deg, #effffd 0%, #ffffff 47.92%, #eff7ff 100%);
}

.h-500 {
  height: 500px;
}

.blue-back {
  background: linear-gradient(180deg, #effffd 0%, #ffffff 47.92%, #effffd 100%);
}

.blue-text {
  color: #515256;
}

.btn-bg {
  background: #2677d9;
}

.input-bg {
  background: linear-gradient(90deg, #4891c0 0%, #5bd2d6 100%);
}
.labels {
  /* background-color: #26C8BE; */
  color: #17bb94;

  /* font-family: sans-serif; */
  border-radius: 0.3rem;
  /* cursor: pointer; */
  /* margin-top: 1rem; */
}
.labels:active {
  color: #16aa86;
}
.label {
  /* background-color: #26C8BE; */
  color: #2677d9;

  /* font-family: sans-serif; */
  border-radius: 0.3rem;
  /* cursor: pointer; */
  /* margin-top: 1rem; */
}
.label:active {
  color: #226ac1;
}
input[type="file"]::file-selector-button {
  visibility: hidden;
}
.feedback {
  /* display: flex; */

  width: 100%;
  background-color: #01a35f;
  min-height: 52px;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  color: white;
  /* font-size: 16px; */
  border-radius: 5px;
  margin-bottom: 5px;
}

.submit {
  color: #000;
}
.submit:active {
  color: #b78811;
}
.dtqzBx div:first-child {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kEIRkz div:first-child {
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.float {
  position: fixed;
  bottom: 50px;
  right: 10px;
  text-align: center;
  padding: 10px 20px 10px 20px;
  box-shadow: 2px 2px 3px #999;
}

.fix {
  text-align: center;
  padding: 10px 20px 10px 20px;
  box-shadow: 2px 2px 3px #999;
}
.welcome {
  border-width: 0px 3px 0px 3px;
  padding-top: 10px;
  border-style: solid;
  text-align: center;
  justify-content: center;
  border-color: #ffa1ec;
  /* background: #FFA1EC; */
  height: 53px;
  width: 256px;
  left: 555px;
  top: 390px;
  border-radius: 6px;
}
.cards {
  height: 330px;
  width: 300px;
  left: 137px;
  top: 1153px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.12);
}
.fishes {
  position: relative;
  top: 0;
  left: 0;
}
.fish {
  position: absolute;
  top: 140px;
  left: 150px;
}
@media only screen and (max-width: 400px) {
  .fish {
    position: absolute;
    top: 40px;
    left: 50px;
  }
  .chat {
    background: "#F4F4F4";
  }
  .fish {
    position: absolute;
    top: 40px;
    left: 50px;
  }
}
@media only screen and (min-width: 600px) {
  .Img {
    height: 250px;
  }
}

.bg {
  object-fit: cover;
  position: absolute;

  z-index: -1;
}
.banner-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0px 40px;
  /* text-align: center; */
  box-sizing: border-box;
}
.videoembed {
  position: absolute;
  top: 0;
  /*z-index: -99; */
  width: 100%;

  /* height: 100%; */
  /* background: #333; */
}
.banner {
  position: relative;
  /* padding-bottom: 40%; */
  width: 100%;
  overflow: hidden;
}
.label {
  display: inline-block;
  border-radius: 0.3rem;
  border: 1 px solid;
  cursor: pointer;
  margin-top: 1rem;
}
/* .chat{
  background: "#FFFFFF"
} */
.rdw-editor-toolbar {
  box-shadow: 5px 1px 5px 1px #d7dcee;
  padding: 10px;
  background: #ffffff;
  border-radius: 6px;
  /* border: 0; */
}
.rdw-editor-main {
  height: 60vh;
  overflow: hidden;
  padding: 10px;
}
.css-1fjvggn-MuiPaper-root-MuiAccordion-root {
  border: 0px !important;
}
.css-n2t2r1-MuiPaper-root-MuiAccordion-root {
  border: 0px !important;
}
.css-9mkapt-MuiTypography-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 2 !important;
}
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}
.css-d3vey3 {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 2 !important;
}
.tox .tox-edit-area__iframe {
  min-height: 400px !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.ask-lr {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 44px;
  height: 44px;
  bottom: 90px;
  right: 60px;
  z-index: 1;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.ask-rp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 140px;
  width: 44px;
  height: 44px;
  right: 60px;
  z-index: 1;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
.feedback2 {
  display: block;
  position: fixed;
  width: 320px;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to perfectly center */
  z-index: 1;
  background-color: #fff;
  border: 1px solid #000;
  color: #fff;
  border-radius: 2%;
  padding: 10px;
  cursor: pointer;
}

.scroll-to-top-button {
  display: block;
  position: fixed;
  height: 44px;
  width: 44px;
  bottom: 40px;
  right: 60px;
  z-index: 1;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.logout {
  background: rgba(201, 201, 201, 1);
}
.logout:hover {
  background: rgba(180, 180, 180, 1);
}

:root {
  /* animations constants */
  --steps: 20;
  --saturation: 80%;
  --lightness: 60%;
  --hue-offset: 320;
  --duration: 3000ms;

  /* generate some colors */
  --color-01: hsl(
    calc(360 / var(--steps) * 1 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-02: hsl(
    calc(360 / var(--steps) * 2 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-03: hsl(
    calc(360 / var(--steps) * 3 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-04: hsl(
    calc(360 / var(--steps) * 4 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-05: hsl(
    calc(360 / var(--steps) * 5 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-06: hsl(
    calc(360 / var(--steps) * 6 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-07: hsl(
    calc(360 / var(--steps) * 7 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-08: hsl(
    calc(360 / var(--steps) * 8 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-09: hsl(
    calc(360 / var(--steps) * 9 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-10: hsl(
    calc(360 / var(--steps) * 10 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-11: hsl(
    calc(360 / var(--steps) * 11 + var(--hue-offset)),
    var(--saturation),
    var(--lightness)
  );
  --color-crayon: #202036;

  /* build some backgrounds */
  --bg-01-a: no-repeat left 0% top / 11% 0%
    linear-gradient(to right, var(--color-01), var(--color-02));
  --bg-01-b: no-repeat left 0% top / 11% 100%
    linear-gradient(to right, var(--color-01), var(--color-02));
  --bg-02-a: no-repeat left 11.11% bottom / 11% 0%
    linear-gradient(to right, var(--color-02), var(--color-03));
  --bg-02-b: no-repeat left 11.11% bottom / 11% 100%
    linear-gradient(to right, var(--color-02), var(--color-03));
  --bg-03-a: no-repeat left 22.22% top / 11% 0%
    linear-gradient(to right, var(--color-03), var(--color-04));
  --bg-03-b: no-repeat left 22.22% top / 11% 100%
    linear-gradient(to right, var(--color-03), var(--color-04));
  --bg-04-a: no-repeat left 33.33% bottom / 11% 0%
    linear-gradient(to right, var(--color-04), var(--color-05));
  --bg-04-b: no-repeat left 33.33% bottom / 11% 100%
    linear-gradient(to right, var(--color-04), var(--color-05));
  --bg-05-a: no-repeat left 44.44% top / 11% 0%
    linear-gradient(to right, var(--color-05), var(--color-06));
  --bg-05-b: no-repeat left 44.44% top / 11% 100%
    linear-gradient(to right, var(--color-05), var(--color-06));
  --bg-06-a: no-repeat left 55.55% bottom / 11% 0%
    linear-gradient(to right, var(--color-06), var(--color-07));
  --bg-06-b: no-repeat left 55.55% bottom / 11% 100%
    linear-gradient(to right, var(--color-06), var(--color-07));
  --bg-07-a: no-repeat left 66.66% top / 11% 0%
    linear-gradient(to right, var(--color-07), var(--color-08));
  --bg-07-b: no-repeat left 66.66% top / 11% 100%
    linear-gradient(to right, var(--color-07), var(--color-08));
  --bg-08-a: no-repeat left 77.77% bottom / 11% 0%
    linear-gradient(to right, var(--color-08), var(--color-09));
  --bg-08-b: no-repeat left 77.77% bottom / 11% 100%
    linear-gradient(to right, var(--color-08), var(--color-09));
  --bg-09-a: no-repeat left 88.88% top / 11% 0%
    linear-gradient(to right, var(--color-09), var(--color-10));
  --bg-09-b: no-repeat left 88.88% top / 11% 100%
    linear-gradient(to right, var(--color-09), var(--color-10));
  --bg-10-a: no-repeat left 99.99% bottom / 11% 0%
    linear-gradient(to right, var(--color-10), var(--color-11));
  --bg-10-b: no-repeat left 99.99% bottom / 12% 100%
    linear-gradient(to right, var(--color-10), var(--color-11));
}

.rainbow-marker-loader {
  height: 3rem;
  width: 8rem;
  max-width: 100%;
  border: 0.5rem solid #353535;
  border-radius: 0.6rem;
  animation: infinite alternate rainbow-fill var(--duration) ease-in-out;
  box-sizing: border-box;
  position: relative;
  margin: 1rem;
  background: var(--bg-01-a), var(--bg-02-a), var(--bg-03-a), var(--bg-04-a),
    var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a),
    var(--bg-09-a), var(--bg-10-a);
}

.rainbow-marker-loader::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: left center;
  border-radius: 0.5rem;
  box-sizing: border-box;
  margin-top: -7.5%;
  margin-left: -2.5%;
  animation: infinite alternate move-marker var(--duration) ease-in-out;
  background: no-repeat left 0% top / 3rem 50%
      linear-gradient(
        to bottom right,
        transparent,
        transparent 40%,
        #353535 40%
      ),
    no-repeat left 0% bottom / 3rem 50%
      linear-gradient(to top right, transparent, transparent 40%, #353535 40%),
    no-repeat left 3rem bottom / 100% 100% linear-gradient(#353535, #353535);
}

@keyframes move-marker {
  10% {
    transform: translate(5%, 100%) rotate(2.5deg);
  }
  20% {
    transform: translate(20%, 0) rotate(-5deg);
  }
  30% {
    transform: translate(30%, 100%) rotate(2.5deg);
  }
  40% {
    transform: translate(40%, 0) rotate(-5deg);
  }
  50% {
    transform: translate(50%, 100%) rotate(2.5deg);
  }
  60% {
    transform: translate(60%, 0) rotate(-5deg);
  }
  70% {
    transform: translate(70%, 100%) rotate(2.5deg);
  }
  80% {
    transform: translate(80%, 0) rotate(-5deg);
  }
  90% {
    transform: translate(90%, 100%) rotate(2.5deg);
  }
  100% {
    transform: translate(100%, 0) rotate(-5deg);
  }
}

@keyframes rainbow-fill {
  0% {
    background: var(--bg-01-a), var(--bg-02-a), var(--bg-03-a), var(--bg-04-a),
      var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a),
      var(--bg-09-a), var(--bg-10-a);
  }
  10% {
    background: var(--bg-01-b), var(--bg-02-a), var(--bg-03-a), var(--bg-04-a),
      var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a),
      var(--bg-09-a), var(--bg-10-a);
  }
  20% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-a), var(--bg-04-a),
      var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a),
      var(--bg-09-a), var(--bg-10-a);
  }
  30% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-a),
      var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a),
      var(--bg-09-a), var(--bg-10-a);
  }
  40% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b),
      var(--bg-05-a), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a),
      var(--bg-09-a), var(--bg-10-a);
  }
  50% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b),
      var(--bg-05-b), var(--bg-06-a), var(--bg-07-a), var(--bg-08-a),
      var(--bg-09-a), var(--bg-10-a);
  }
  60% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b),
      var(--bg-05-b), var(--bg-06-b), var(--bg-07-a), var(--bg-08-a),
      var(--bg-09-a), var(--bg-10-a);
  }
  70% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b),
      var(--bg-05-b), var(--bg-06-b), var(--bg-07-b), var(--bg-08-a),
      var(--bg-09-a), var(--bg-10-a);
  }
  80% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b),
      var(--bg-05-b), var(--bg-06-b), var(--bg-07-b), var(--bg-08-b),
      var(--bg-09-a), var(--bg-10-a);
  }
  90% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b),
      var(--bg-05-b), var(--bg-06-b), var(--bg-07-b), var(--bg-08-b),
      var(--bg-09-b), var(--bg-10-a);
  }
  100% {
    background: var(--bg-01-b), var(--bg-02-b), var(--bg-03-b), var(--bg-04-b),
      var(--bg-05-b), var(--bg-06-b), var(--bg-07-b), var(--bg-08-b),
      var(--bg-09-b), var(--bg-10-b);
  }
}

.loader-title {
  color: #353535;
  font-size: 2rem;
  font-family: "Righteous", sans-serif;
  letter-spacing: 1px;
}

.literature-loader {
  color: #353535;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
}
.card-element-container {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
#myTextarea::before {
  content: "First line of placeholder text";
  display: block;
  font-style: italic;
  color: #999999;
}

#myTextarea::after {
  content: "Second line of placeholder text";
  display: block;
  font-style: italic;
  color: #999999;
}
.input-box:focus {
  background-color: #ffff00;
  /* color:#FFF; */
  /* border-color: #007bff; */
  box-shadow: 0 0 15px;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  /* height: 25px; */
  background-color: #353535cc;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 150%; */
  left: 50%;
  margin-left: -60px;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tooltip:focus .tooltiptext {
  visibility: visible;
}
.tooltiptext {
  top: -15px;
  /* left: 105%; */
}
.index {
  z-index: 1;
}
.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  position: relative;
  font-weight: 300;
  font-style: italic;
}
.mce-content-body[data-mce-placeholder]::before {
  color: rgba(34, 47, 62, 0.7);
  content: attr(data-mce-placeholder);
  position: absolute;
  white-space: pre;
}
.pdfPage {
  background-color: #fff59d !important;
  box-shadow: 2px rgba(0, 0, 0, 0.08);
}
.highlight {
  background-color: yellow;
  opacity: 0.5;
  position: absolute;
}
.continuous-animated-button {
  animation: pulse 1s infinite alternate ease-in-out;
  color: "#FFF";
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}

@mixin white-gradient {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 1));
  }
}

.swiper-wrapper {
  height: auto;
}
.swiper-pagination {
  position: relative;
  margin-top: 20px;
}
